import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ContentComponent } from './content/content.component';
import { ContactComponent } from './contact/contact.component';
import { ProductComponent } from './product/product.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PresentationComponent } from './presentation/presentation.component';
import { VisionComponent } from './vision/vision.component';
import { ValuesComponent } from './values/values.component';
import { EngineeringComponent } from './engineering/engineering.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { FormationComponent } from './formation/formation.component';
import { AuditComponent } from './audit/audit.component';
import { MaterialGazComponent } from './material-gaz/material-gaz.component';
import { FreonComponent } from './freon/freon.component';
import { GazAirComponent } from './gaz-air/gaz-air.component';
import { MedicalOxComponent } from './medical-ox/medical-ox.component';
import { ArgonComponent } from './argon/argon.component';
import { AzoteComponent } from './azote/azote.component';
import { HeliumComponent } from './helium/helium.component';
import { IndustrialOxComponent } from './industrial-ox/industrial-ox.component';
import { CotwoComponent } from './cotwo/cotwo.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { PurGasComponent } from './pur-gas/pur-gas.component';
import { MedicalMaterialComponent } from './medical-material/medical-material.component';
import { FormsModule } from '@angular/forms';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ContentComponent,
    ContactComponent,
    ProductComponent,
    PresentationComponent,
    VisionComponent,
    ValuesComponent,
    EngineeringComponent,
    MaintenanceComponent,
    FormationComponent,
    AuditComponent,
    MaterialGazComponent,
    FreonComponent,
    GazAirComponent,
    MedicalOxComponent,
    ArgonComponent,
    AzoteComponent,
    HeliumComponent,
    IndustrialOxComponent,
    CotwoComponent,
    PurGasComponent,
    MedicalMaterialComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    LeafletModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [HttpClientModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
