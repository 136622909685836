import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-medical-ox',
  templateUrl: './medical-ox.component.html',
  styleUrls: ['./medical-ox.component.css']
})
export class MedicalOxComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
