import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-material-gaz',
  templateUrl: './material-gaz.component.html',
  styleUrls: ['./material-gaz.component.css']
})
export class MaterialGazComponent implements OnInit {

  products: any[];
  showTable: boolean;
  categories: string[];
  subCategory: any;
  toggle = {};
  activeProducts: any[];
  activeHeaders: any[];
  activeGamme: string;
  enHeaders: any;
  frHeaders: any;
  activeLanguage: string;
  private productLink = './assets/products.json';

  constructor(private router: Router, private http: HttpClient, public translate: TranslateService ) {
    this.showTable = false;
    this.getJSON().subscribe(data => {
      this.products = data.products;
      this.categories = data.products.map(e => e.type);
      this.categories.forEach(element => {
        this.toggle[element] = true;
      });
    });
  }

  public getJSON(): Observable<any> {
    return this.http.get(this.productLink);
  }

  ngOnInit() {
    this.activeLanguage = this.translate.currentLang;
    if (this.activeLanguage === 'fr') {
      this.activeHeaders = this.frHeaders;
    } else if (this.activeLanguage === 'en') {
      this.activeHeaders = this.enHeaders;
    }
    this.translate.onLangChange.subscribe(e => {
      this.activeLanguage = e.lang;
      if (e.lang === 'fr') {
        this.activeHeaders = this.frHeaders;
      } else if (e.lang === 'en') {
        this.activeHeaders = this.enHeaders;
      }
    });

  }

  public showTableCall() {
    this.showTable = !this.showTable;
  }

  public toggleMenu(menu) {
    this.showTable = false;
    this.toggle[menu] = !this.toggle[menu];
  }

  public truncate(word: string) {
    return [word.substring(0, word.length - 3), word.substring(word.length - 3, word.length)]
  }

  public getCategories(key: string) {
    return this.products.filter(e => e.type === key).map(e => e.subCategories)[0];
  }

  public loadTable(subCategory: any) {
    this.showTable = true;
    for (const key in this.toggle) {
      if (this.toggle[key] === false) {
        this.activeGamme = key;
      }
    }
    this.subCategory = subCategory;
    this.activeProducts = subCategory.products || [];
    this.enHeaders = subCategory.enHeaders || [];
    this.frHeaders = subCategory.frHeaders || [];
    if (this.translate.currentLang === 'fr') {
      this.activeHeaders = this.frHeaders;
    } else if (this.translate.currentLang === 'en') {
      this.activeHeaders = this.enHeaders;
    }
  }


}
