import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gaz-air',
  templateUrl: './gaz-air.component.html',
  styleUrls: ['./gaz-air.component.css']
})
export class GazAirComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
