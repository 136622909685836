import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-azote',
  templateUrl: './azote.component.html',
  styleUrls: ['./azote.component.css']
})
export class AzoteComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
