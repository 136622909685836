import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-medical-material',
  templateUrl: './medical-material.component.html',
  styleUrls: ['./medical-material.component.css']
})
export class MedicalMaterialComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
