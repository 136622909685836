import { Component, OnInit } from '@angular/core';
import { tileLayer, latLng, marker, icon } from 'leaflet';

import emailjs from 'emailjs-com';
import apiKeys from '../../assets/apiKeys';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  layer: any;
  formContent = {
    'fName': '',
    'lName': '',
    'email': '',
    'subject': '',
    'message': ''
  };
  success: boolean = null;

  form: HTMLFormElement;
  
  constructor() { }

  options = {
    layers: [
      tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png')
    ],
    zoom: 13,
    center: latLng(33.606560, -7.488788)
  };

  ngOnInit() {
    this.layer = [marker([33.606560, -7.488788])];
  }

  onSubmit() {
    this.form = document.querySelector('#form');
    emailjs
    .sendForm(apiKeys.SERVICE_ID, apiKeys.TEMPLATE_ID, this.form, apiKeys.USER_ID)
    .then(
      result => {
        this.success = true;
        console.log(result.text);
      },
      error => {
        this.success = false;
        console.log(error.text);
      }
    )
}

}
