import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContentComponent } from './content/content.component';
import { ContactComponent } from './contact/contact.component';
import { ProductComponent } from './product/product.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { EngineeringComponent } from './engineering/engineering.component';
import { FormationComponent } from './formation/formation.component';
import { ValuesComponent } from './values/values.component';
import { VisionComponent } from './vision/vision.component';
import { FreonComponent } from './freon/freon.component';
import { PresentationComponent } from './presentation/presentation.component';
import { AuditComponent } from './audit/audit.component';
import { MaterialGazComponent } from './material-gaz/material-gaz.component';
import { MedicalOxComponent } from './medical-ox/medical-ox.component';
import { IndustrialOxComponent } from './industrial-ox/industrial-ox.component';
import { ArgonComponent } from './argon/argon.component';
import { AzoteComponent } from './azote/azote.component';
import { HeliumComponent } from './helium/helium.component';
import { CotwoComponent } from './cotwo/cotwo.component';
// import { GazAirComponent } from './gaz-air/gaz-air.component';
import { PurGasComponent } from './pur-gas/pur-gas.component';
import { MedicalMaterialComponent } from './medical-material/medical-material.component';



const routes: Routes = [
  { path: '', component: ContentComponent },
  { path: 'home', component: ContentComponent},
  { path: 'contact', component: ContactComponent},
  { path: 'product', component: ProductComponent },
  { path: 'product/:title', component: ProductComponent },
  { path: 'maintenance', component: MaintenanceComponent },
  { path: 'formation', component: FormationComponent },
  { path: 'engineering', component: EngineeringComponent },
  { path: 'presentation', component: PresentationComponent },
  { path: 'material-gaz', component: MaterialGazComponent },
  { path: 'values', component: ValuesComponent },
  { path: 'vision', component: VisionComponent },
  /*
  { path: 'gaz-air', component: GazAirComponent },
  */
  { path: 'coTwo', component: CotwoComponent },
  { path: 'argon', component: ArgonComponent },
  { path: 'azote', component: AzoteComponent },
  { path: 'helium', component: HeliumComponent },
  { path: 'industrial-ox', component: IndustrialOxComponent },
  { path: 'medical-ox', component: MedicalOxComponent },
  { path: 'freon', component: FreonComponent },
  { path: 'pur-gas', component: PurGasComponent },
  { path: 'medical-material', component: MedicalMaterialComponent },
  { path: 'audit', component: AuditComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [ContentComponent, ProductComponent, ContactComponent];
