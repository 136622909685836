import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { namespaceHTML } from '@angular/core/src/render3';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {

  private _jsonURL = './assets/products.json';
  private products: any[];
  private frHeaders: any;
  private enHeaders: any;
  public activeHeaders: any;
  public currentProduct: any;
  activeLanguage: string;

  constructor(private http: HttpClient, private router: Router, public translate: TranslateService) {
    String.prototype['replaceAll'] = function (search, replacement) {
      var target = this;
      return target.split(search).join(replacement);
    };
    const productModel = decodeURIComponent(this.router.url.split('/')[2]);
    console.log('passing request to : ' + this._jsonURL);
    this.getJSON().subscribe(data => {
      data.products.forEach(element => {
       element.subCategories.forEach(subCategory => {
        subCategory.products.forEach(product => {
          if (product.Model === productModel) {
            this.frHeaders = subCategory.frHeaders;
            this.enHeaders = subCategory.enHeaders;
            if (this.translate.getDefaultLang() === 'fr') {
                this.activeHeaders = this.frHeaders;
            } else if (this.translate.getDefaultLang() === 'en') {
                this.activeHeaders = this.enHeaders;
            }
            this.currentProduct = product;
          }
        });
       });
     });
    });
  }

  public getJSON(): Observable<any> {
    return this.http.get(this._jsonURL);
  }

  public hasPdf(value: string): boolean {
    if(this.currentProduct !== undefined){
      this.activeLanguage === 'fr' ? value = 'frPdf' : value = 'enPdf';
      if(this.currentProduct.hasOwnProperty(value)){
        if(this.currentProduct.enPdf !== null && this.currentProduct.enPdf !== ''){
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  }

  public generateDownloadLink(name: string){
    if (name.includes('.pdf')){
      return name;
    } else {
      return name + '.pdf';
    }
  }

  public hasImage(): boolean {
    if(this.currentProduct !== undefined){
      if(this.currentProduct.hasOwnProperty('img')){
        if(this.currentProduct.img !== null && this.currentProduct.img !== undefined && this.currentProduct.img !== ''){
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  }

  ngOnInit() {
    this.activeLanguage = this.translate.currentLang;
    if (this.activeLanguage === 'fr') {
      this.activeHeaders = this.frHeaders;
    } else if (this.activeLanguage === 'en') {
      this.activeHeaders = this.enHeaders;
    }
    this.translate.onLangChange.subscribe(e => {
      if (e.lang === 'fr') {
        this.activeLanguage = 'fr';
        this.activeHeaders = this.frHeaders;
      } else if (e.lang === 'en') {
        this.activeLanguage = 'en';
        this.activeHeaders = this.enHeaders;
      }
    });
  }
}
