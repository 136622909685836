import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-freon',
  templateUrl: './freon.component.html',
  styleUrls: ['./freon.component.css']
})
export class FreonComponent implements OnInit {

  products: any[];
  currentLang: string;
  private productLink = './assets/freon.json';

  constructor(private http: HttpClient, private TranslateService: TranslateService) { }

  ngOnInit() {
    this.currentLang = this.TranslateService.currentLang;
    this.getJSON().subscribe(data => {
      this.products = data.products;
    });
    this.TranslateService.onLangChange.subscribe(e => {
      this.currentLang = e.lang;
    });
  }

  public getJSON(): Observable<any> {
    return this.http.get(this.productLink);
  }
}
