import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  highlightedFr: boolean;
  highlightedEn: boolean;

  constructor(private TranslateService: TranslateService) { }

  ngOnInit() {
    this.highlightedFr = true;
    this.highlightedEn = false;
  }

  setLangFr() {
    this.TranslateService.use('fr');
    this.highlightedFr = true;
    this.highlightedEn = false;
  }

  setLangEn() {
    this.TranslateService.use('en');
    this.highlightedFr = false;
    this.highlightedEn = true;
  }

}
